import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import { Collection } from "@utils/globalTypes"
import CollectionCard from "../components/CollectionCard"
import * as s from "./CollectionsContainer.module.scss"

const orderCollections = (unOrderedCollections: any) => {
  const colections = []

  if (unOrderedCollections)
    unOrderedCollections.forEach((item) => {
      switch (item.title) {
        case "Workplace Drug Testing":
          colections[0] = item
          break
        case "Government Services":
          colections[1] = item
          break
        case "Retail Partnership Opportunities":
          colections[2] = item
          break
        default:
          colections.push(item)
          break
      }
    })

  return colections
}

type Props = {
  collections: Collection[]
}
const CollectionsContainer: FC<Props> = ({ collections }) => {
  return (
    <Container>
      <div className={s.container}>
        <Row className={s.titleContainer}>
          <div>
            <h2 className={s.title}>Equip your business with accurate, affordable screening tools.</h2>
            <p className={s.subtitle}>Explore our drug testing categories.</p>
          </div>
        </Row>
        <Row>
          {orderCollections(collections).map((collection, index) => (
            <Col key={index} className="mb-3" xs={12} md={6} lg={4}>
              <CollectionCard collection={collection} />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  )
}

export default CollectionsContainer
