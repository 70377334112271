import { AllGraphQLResponse, Collection, SanityPage } from "@utils/globalTypes"
import React, { FC } from "react"
import loadable from "@loadable/component"
import SEO from "@components/common/SEO"
import { graphql } from "gatsby"
import TitleContainer from "@components/common/TitleContainer"
const WhyContainer = loadable(() => import("@components/homePage/WhyContainer"))
const BannerContainer = loadable(
  () => import("@components/homePage/BannerContainer")
)
const CarouselContainer = loadable(
  () => import("@components/homePage/CarouselContainer")
)
import CollectionsContainer from "@components/homePage/CollectionsContainer"
const FeatureBenefitContainer = loadable(
  () => import("@components/homePage/FeatureBenefitContainer")
)
const GuideResourceContainer = loadable(
  () => import("@components/homePage/GuideResourceContainer")
)
const TeamInfoContainer = loadable(
  () => import("@components/homePage/TeamInfoContainer")
)

type Props = {
  data: {
    sanityPage: SanityPage
    allShopifyCollection: AllGraphQLResponse<Collection>
  }
  location: Location
}

const IndexPage: FC<Props> = ({
  data: {
    sanityPage,
    allShopifyCollection: { edges },
  },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const collections = edges.map((edge) => edge.node)

  return (
    <>
      <SEO {...metadata} />
      {sanityPage && (
        <>
          <TitleContainer
            backgroundImage={sanityPage?.backgroundImage}
            title={sanityPage?.title}
            subtitle={sanityPage?.subtitle}
            image={sanityPage?.image}
          />
          <CollectionsContainer collections={collections} />
          <BannerContainer />
          <WhyContainer sanityPage={sanityPage} />
          <TeamInfoContainer sanityPage={sanityPage} />
          <FeatureBenefitContainer sanityPage={sanityPage} />
          <GuideResourceContainer sanityPage={sanityPage} />
          <CarouselContainer sanityPage={sanityPage} />
        </>
      )}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    sanityPage(_id: { in: ["drafts.frontpage", "frontpage", "homePage"] }) {
      id
      subtitle
      title
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      backgroundImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      content {
        ... on SanityCompanyIntro {
          title
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          _rawText
          _type
        }
        ... on SanityQuote {
          _key
          _type
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          _rawText
          buttonLink {
            label
            link
          }
        }
        ... on SanityFeaturesBenefits {
          _key
          _type
          featureBenefit {
            title
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        ... on SanityPartners {
          _type
          images {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        ... on SanityResources {
          _type
          resources {
            ... on SanityLandingPage {
              _type
              title
              handle {
                current
              }
            }
            ... on SanityResourcePage {
              _type
              title
              slug {
                current
              }
              category {
                name
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
    allShopifyCollection(
      filter: {
        handle: {
          in: ["retail-brands", "government-services", "drug-of-abuse-testing"]
        }
      }
    ) {
      edges {
        node {
          id
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          handle
        }
      }
    }
  }
`
