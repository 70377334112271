import React, { FC } from "react"

import { Card } from "react-bootstrap"
import { Collection } from "@utils/globalTypes"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import loadable from "@loadable/component"
const TextWithArrow = loadable(() => import("@components/common/TextWithArrow"))
import * as s from "./CollectionCard.module.scss"

type Props = {
  collection: Collection
}

const CollectionCard: FC<Props> = ({ collection }) => {
  const { title, handle, image } = collection

  const renderImage = () => {
    return (
      <div className={s.imgContainer}>
        {image ? (
          <GatsbyImage
            className={s.img}
            alt={title}
            image={image.localFile.childImageSharp?.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
          />
        ) : (
          <div className={s.imgPlaceholder}></div>
        )}
      </div>
    )
  }

  return (
    <Link to={`/products/${handle}`}>
      <Card className={s.card}>
        <Card.Body className={s.cardBody}>
          <Card.Title className={s.title}>{title}</Card.Title>
          {renderImage()}
          <Card.Link as="div" className={s.linkContainer}>
            <TextWithArrow text="Learn More" />
          </Card.Link>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default CollectionCard
